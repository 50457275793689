var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"body-class":"p-0"}},[_c('div',{staticClass:"text-center bg-primary"},[_c('strong',[_c('i',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.vehicle.condition)+" ")])])]),_c('VehicleCardGallery',{attrs:{"images":_vm.vehicleImages,"typeList":_vm.typeList}}),_c('b-card-text',[_c('b-container',{staticClass:"p-3"},[_c('b-row',[_c('b-col',{staticClass:"text-left",attrs:{"cols":"7"}},[(_vm.vehicle.vinNumber)?_c('router-link',{attrs:{"to":_vm.vehiclePageURL}},[_c('div',[_c('i',[_vm._v(" "+_vm._s(_vm.cardVehicleTitle)+" ")])])]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.vehicle.assetGroup))])],1),_c('b-col',{staticClass:"text-right",attrs:{"cols":"5"}},[(!_vm.companyIsPending && _vm.vehicle && _vm.vehicle.priceRetail)?_c('span',{staticClass:"text-success"},[_c('i',[_vm._v(_vm._s(_vm._f("currency")(_vm.vehicle.priceRetail)))])]):_vm._e()]),_c('b-col',{staticClass:"text-left",attrs:{"cols":"7"}},[(_vm.vehicle.vinNumber)?_c('span',[_c('span',[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.vehicle.miles))+" Kms")])]):_vm._e()]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"5"}},[(_vm.vehicle.stockNumber)?_c('span',[_c('span',[_vm._v(_vm._s(_vm.vehicle.stockNumber)+" ")])]):_vm._e()]),(_vm.vehicle.vinNumber)?_c('b-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('vue-barcode',{staticClass:"bar-code",staticStyle:{"width":"100%"},attrs:{"value":_vm.vehicle.vinNumber,"options":_vm.barcodeOptions}}),_c('i',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.vehicle.vinNumber || _vm.vehicle.vinNumber)+" "),_c('b-button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(
                 _vm.vehicle.vinNumber ? _vm.vehicle.vinNumber : _vm.vehicle.vinNumber
               ),expression:"\n                 vehicle.vinNumber ? vehicle.vinNumber : vehicle.vinNumber\n               ",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.copyVinNumber),expression:"copyVinNumber",arg:"success"}],attrs:{"variant":"none"}},[(_vm.vinCopied)?_c('b-icon',{attrs:{"icon":"clipboard-check"}}):_c('b-icon',{attrs:{"icon":"clipboard"}})],1),(_vm.vehicle.quoteFile)?_c('a',{attrs:{"href":_vm.vehicle.quoteFile,"target":"_blank"}},[_c('b-button',{staticClass:"p-0",attrs:{"variant":"none"}},[_c('b-icon',{staticClass:"cursor-pointer",attrs:{"icon":"file-earmark","font-scale":"1"}})],1)],1):_vm._e()],1)],1):_vm._e()],1),_c('b-row',{},[_c('b-col',{staticClass:"text-left d-flex align-items-center",attrs:{"cols":"6"}}),_c('b-col',{staticClass:"d-flex justify-content-end text-right",attrs:{"cols":"6"}})],1),(_vm.vehicleAcceptOffer || _vm.vehicle.status === 'Sold')?_c('div',{staticClass:"mt-2"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/img/TOOLATE.png"),"alt":"sold-image"}})]):_c('div',[(_vm.viewOffers)?_c('b-row',{staticClass:"mt-3"},[_c('b-col',{staticClass:"mb-1"},[_c('b-button',{staticClass:"shadow",attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.showVehicleOffers()}}},[_vm._v(" "+_vm._s(_vm.$t("viewOffers"))+" ")])],1)],1):(!_vm.isHome && _vm.user)?_c('b-row',{staticClass:"mt-2"},[_c('b-col',[(_vm.vehicle.status !== 'Inactive' && _vm.userIsAdmin)?_c('b-button',{staticClass:"mt-1",attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.editVehicle(_vm.vehicle.vinNumber)}}},[_c('b-icon',{attrs:{"icon":"pencil"}})],1):_vm._e()],1)],1):_vm._e(),(
             !_vm.isHome &&
             !_vm.isActiveOffer &&
             !_vm.isExpiredOffer &&
             (_vm.userHasOffer || _vm.vehicle.lastOffer) &&
             _vm.userHasCompany
           )?_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('b-button',{attrs:{"variant":"primary","block":"","size":"sm"},on:{"click":function($event){return _vm.changeOfferTab(_vm.vehicle.id)}}},[_c('h5',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(_vm.$t("currentOffer"))+": "),(_vm.vehicle.lastOffer)?_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.vehicle.lastOffer.offerPrice))+" ")]):(_vm.userHasOffer)?_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.currentOffer))+" ")]):_vm._e()])])],1)],1):_vm._e(),(
             !_vm.isHome &&
             !_vm.userHasOffer &&
             !_vm.vehicle.lastOffer &&
             !_vm.isExpiredOffer &&
             !_vm.isActiveOffer &&
             !_vm.userIsAdmin &&
             !_vm.userIsAgent &&
             !_vm.userIsRetail
           )?_c('MakeAnOffer',{staticClass:"mt-3",attrs:{"vehicleId":this.vehicle.id,"price":this.vehicle.priceRetail,"userPending":!_vm.userHasCompany,"vehicle":_vm.vehicle},on:{"setUserHasOffer":function($event){return _vm.setUserHasOffer($event)}}}):_vm._e(),_c('b-button',{staticClass:"mt-2",attrs:{"variant":"primary","block":""},on:{"click":function($event){_vm.copyToClipboard(_vm.generateUrlToCopy(_vm.item))}}},[_vm._v(" "+_vm._s(_vm.$t("copyLink"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }